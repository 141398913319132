import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import {
  Button,
  LoaderContainer,
  Page,
  Pagination,
  SortBy,
  Spinner,
  Table,
  Text,
} from '@ui';
import React, { FC, useMemo, useRef, useState } from 'react';
import { OrderDetailsModal, SalesDetails } from './ui';
import { useBreakpoints, useToast } from '@hooks';
import {
  DateFormats,
  downloadCSVFromURL,
  formatDate,
  formatNumber,
} from '@utils';
import { DateRangeFilter } from '@features';
import qs from 'qs';

import {
  ORDERS_LIMIT,
  OrderSale,
  REFUNDS_LIMIT,
  Refund,
  VOIDS_LIMIT,
  Void,
  useGetOrdersCSVMutation,
  useGetOrdersQuery,
  useGetOrdersStatisticsQuery,
  useGetRefundsQuery,
  useGetVoidsQuery,
} from '@api';
import { match } from 'ts-pattern';
import { useSelector } from '@store';
import { filtersSelectors } from '@store/entities';
import classNames from 'classnames';

export const SalesDashboard: FC = () => {
  const { showErrorToast } = useToast();
  const { isDesktop } = useBreakpoints();
  const columnHelper = createColumnHelper<OrderSale | Refund | Void>();

  const [currentPage, setCurrentPage] = useState(1);

  const dateRangeFilters = useSelector(filtersSelectors.unixDateRangeFilter);
  const selectedLocations = useSelector(filtersSelectors.selectedLocations);

  const [activeOption, setActiveOption] = useState<
    'orders' | 'refunds' | 'voids'
  >('orders');

  const locationsIds = selectedLocations.map((location) => location.id);

  const [activeRowIndex, setActiveRowIndex] = useState(-1);
  const [isOrderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);

  const {
    data: ordersData,
    isLoading: isOrdersLoading,
    isFetching: isOrdersFetching,
  } = useGetOrdersQuery(
    {
      page: currentPage,
      ...dateRangeFilters,
      locations: locationsIds,
    },
    {
      skip: activeOption !== 'orders',
    }
  );

  const { data: ordersStatistics, isLoading: isMetricsLoading } =
    useGetOrdersStatisticsQuery(
      {
        ...dateRangeFilters,
        locations: locationsIds,
      },
      {
        skip: !isDesktop && activeOption !== 'orders',
      }
    );

  const {
    data: refundsData,
    isLoading: isRefundsLoading,
    isFetching: isRefundsFetching,
  } = useGetRefundsQuery(
    {
      page: currentPage,
      ...dateRangeFilters,
      locations: locationsIds,
    },
    {
      skip: activeOption !== 'refunds',
    }
  );

  const {
    data: voidsData,
    isLoading: isVoidsLoading,
    isFetching: isVoidsFetching,
  } = useGetVoidsQuery(
    {
      page: currentPage,
      ...dateRangeFilters,
      locations: locationsIds,
    },
    {
      skip: activeOption !== 'voids',
    }
  );
  const [exportCV, { isLoading: isCsvLoading }] = useGetOrdersCSVMutation();

  // const onExportCV = async () => {
  //   try {
  //     const result = await exportCV({
  //       ...dateRangeFilters,
  //       locations: locationsIds,
  //     }).unwrap();
  //
  //     // Assuming result is a URL string:
  //     const link = document.createElement('a');
  //     link.href = result;
  //     link.setAttribute('download', `${formatDate(new Date(),
  //     DateFormats.SHORT_FORMAT)}-orders.csv`);
  //     document.body.appendChild(link);
  //     link.click(); // Trigger the download
  //
  //     document.body.removeChild(link); // Cleanup after download
  //   } catch (error) {
  //     console.error('Error downloading CSV:', error);
  //     showErrorToast(); // Inform the user of the failure
  //   }
  // };

  const onExportCV = async () => {
    try {
      // Directly calculate Unix timestamps from the Date objects
      const startDateUnix = new Date(dateRangeFilters.startDate * 1000).getTime() / 1000;
      const endDateUnix = new Date(dateRangeFilters.endDate * 1000).getTime() / 1000;

      const queryString = qs.stringify({
        starting_date: startDateUnix,
        ending_date: endDateUnix,
        locations: locationsIds.join(','),
      });

      // const downloadUrl = `https://amigo-dashboard-backend-a47d14897a1b.herokuapp.com/orders_csv?${queryString}`;
      const downloadUrl = `https://cloud-backend-python.onrender.com/orders_csv?${queryString}`;

      // Create an anchor element and trigger the download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `${formatDate(new Date(), DateFormats.SHORT_FORMAT)}-orders.csv`);
      document.body.appendChild(link);
      link.click(); // Trigger the download

      document.body.removeChild(link); // Cleanup after download
    } catch (error) {
      console.error('Error triggering CSV download:', error);
      showErrorToast(); // Inform the user of the failure
    }
  };

  const addLocationColumn = (columns: ColumnDef<any, any>[]) => {
    if (selectedLocations.length > 1) {
      columns.splice(
        0,
        0,
        columnHelper.accessor(
          (row: any) => {
            const locationName = selectedLocations.find(
              (location) => location.id === row.location_id
            )?.display_name;
            return locationName;
          },
          {
            header: 'LOCATIONS',
            cell: (cell) => cell.getValue() || '-',
          }
        )
      );
    }
  };

  const columns = useMemo(() => {
    if (activeOption === 'orders') {
      const tableCols = [
        columnHelper.accessor((row: any) => row.order_id, {
          header: 'ORDER ID',
          cell: (cell) => cell.getValue() || '-',
        }),
        columnHelper.accessor((row: any) => row.source, {
          header: 'SOURCE',
          cell: (cell) => cell.getValue() || '-',
        }),
        columnHelper.accessor(
          (row: any) => {
            if (row.timestamp) {
              const timestamp = row.timestamp * 1000; // Convert to milliseconds
              const torontoDateTime = new Date(timestamp).toLocaleString(
                'en-US',
                {
                  timeZone: 'America/Toronto',
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  hour12: true,
                }
              );
              return torontoDateTime;
            }
            return '-';
          },
          {
            header: 'TIME',
            cell: (cell) => cell.getValue(),
          }
        ),
        columnHelper.accessor((row: any) => row.ticket_number, {
          header: 'TICKET #',
          cell: (cell) => cell.getValue() || '-',
        }),
        columnHelper.accessor((row: any) => row.total, {
          header: 'TOTAL',
          cell: (cell) => `$${formatNumber(cell.getValue())}` || '-',
        }),
      ];

      addLocationColumn(tableCols);
      return tableCols;
    }

    if (activeOption === 'refunds') {
      const tableCols = [
        columnHelper.accessor((row: any) => row.order_id, {
          header: 'ORDER ID',
          cell: (cell) => cell.getValue() || '-',
        }),
        columnHelper.accessor(
          (row: any) => {
            if (row.timestamp) {
              const timestamp = row.timestamp * 1000; // Convert to milliseconds
              const torontoDateTime = new Date(timestamp).toLocaleString(
                'en-US',
                {
                  timeZone: 'America/Toronto',
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  hour12: true,
                }
              );
              return torontoDateTime;
            }
            return '-';
          },
          {
            header: 'TIME',
            cell: (cell) => cell.getValue(),
          }
        ),
        columnHelper.accessor((row: any) => row.reason, {
          header: 'REASON',
          cell: (cell) => cell.getValue() || '-',
        }),
        columnHelper.accessor((row: any) => row.method, {
          header: 'METHOD',
          cell: (cell) => cell.getValue() || '-',
        }),
        columnHelper.accessor((row: any) => row.amount, {
          header: 'TOTAL',
          cell: (cell) => `$${formatNumber(cell.getValue())}` || '-',
        }),
      ];

      addLocationColumn(tableCols);
      return tableCols;
    }

    if (activeOption === 'voids') {
      const tableCols = [
        columnHelper.accessor((row: any) => row.order_id, {
          header: 'ORDER ID',
          cell: (cell) => cell.getValue() || '-',
        }),
        columnHelper.accessor(
          (row: any) => {
            if (row.void_time) {
              const timestamp = row.void_time * 1000; // Convert to milliseconds
              const torontoDateTime = new Date(timestamp).toLocaleString(
                'en-US',
                {
                  timeZone: 'America/Toronto',
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  hour12: true,
                }
              );
              return torontoDateTime;
            }
            return '-';
          },
          {
            header: 'TIME',
            cell: (cell) => cell.getValue(),
          }
        ),
        columnHelper.accessor((row: any) => row.void_amount, {
          header: 'TOTAL',
          cell: (cell) => `$${formatNumber(cell.getValue())}` || '-',
        }),
      ];

      addLocationColumn(tableCols);
      return tableCols;
    }

    return [];
  }, [selectedLocations.length, activeOption]);

  const onClickRow = (index: number) => {
    setActiveRowIndex(index);
    setIsOrderDetailsModalOpen(true);
  };

  const refsArray = [
    useRef<HTMLButtonElement>(null),
    useRef<HTMLButtonElement>(null),
    useRef<HTMLButtonElement>(null),
    useRef<HTMLButtonElement>(null),
    useRef<HTMLButtonElement>(null),
  ];

  const columnsNames = columns.map((col) => String(col.header));

  function getData() {
    if (activeOption === 'refunds') {
      return (refundsData?.refunds || []) as unknown as Refund[];
    }
    if (activeOption === 'voids') {
      return (voidsData?.voids || []) as unknown as Void[];
    }
    return (ordersData?.orders || []) as unknown as OrderSale[];
  }

  function getPaginationProps() {
    if (activeOption === 'refunds') {
      return {
        pageSize: REFUNDS_LIMIT,
        count: refundsData?.total,
      };
    }
    if (activeOption === 'voids') {
      return {
        pageSize: VOIDS_LIMIT,
        count: voidsData?.total,
      };
    }

    return {
      pageSize: ORDERS_LIMIT,
      count: ordersData?.total,
    };
  }

  const paginationProps = getPaginationProps();

  const getOrderDetailProps = () => {
    if (activeOption === 'orders') {
      return {
        orderId: ordersData?.orders[activeRowIndex]?.order_id || 0,
        locationId: ordersData?.orders[activeRowIndex]?.location_id || 0,
      };
    }
    if (activeOption === 'refunds') {
      return {
        orderId: refundsData?.refunds[activeRowIndex]?.order_id || 0,
        locationId: refundsData?.refunds[activeRowIndex]?.location_id || 0,
      };
    }
    if (activeOption === 'voids') {
      return {
        orderId: voidsData?.voids[activeRowIndex]?.order_id || 0,
        locationId: voidsData?.voids[activeRowIndex]?.location_id || 0,
      };
    }
    return {
      orderId: 0,
      locationId: 0,
    };
  };

  const orderDetailsProps = getOrderDetailProps();

  return (
    <Page title="Sales Dashboard" withIndicator positive>
      <div className="flex mb-5">
        <button
          onClick={() => {
            setActiveOption('orders');
            setCurrentPage(1);
          }}
          type="button"
          className={classNames(
            activeOption === 'orders' ? 'button-contained' : 'button-outlined ',
            'button-default border-r-0 rounded-r-none w-[93px]'
          )}
        >
          Orders
        </button>
        <button
          onClick={() => {
            setActiveOption('refunds');
            setCurrentPage(1);
          }}
          type="button"
          className={classNames(
            activeOption === 'refunds'
              ? 'button-contained'
              : 'button-outlined ',
            'button-default rounded-none w-[105px]'
          )}
        >
          Refunds
        </button>
        <button
          onClick={() => {
            setActiveOption('voids');
            setCurrentPage(1);
          }}
          type="button"
          className={classNames(
            activeOption === 'voids' ? 'button-contained' : 'button-outlined ',
            'button-default border-l-0 rounded-l-none w-[85px]'
          )}
        >
          Voids
        </button>
      </div>
      <DateRangeFilter />
      {/* <br /> */}
      {/* {match(isMetricsLoading || isOrdersLoading) */}
      {match(
        false ||
          isOrdersLoading ||
          isMetricsLoading ||
          isRefundsLoading ||
          isVoidsLoading
      )
        .with(true, () => (
          <div className="flex-center h-[400px]">
            <Spinner remSize={2.5} />
          </div>
        ))
        .with(false, () =>
          ordersData?.orders ? (
            <div className="mt-5">
              {isDesktop &&
                !isMetricsLoading &&
                activeOption === 'orders' &&
                ordersStatistics && <SalesDetails metrics={ordersStatistics} />}

              <div className="flex flex-col flex-wrap items-center justify-between gap-y-8 l:flex-row l:mt-10 gap-x-4 ">
                {/* <DateRangeFilter /> */}

                <div className="w-full l:w-fit flex-between ">
                  {!isDesktop && (
                    <SortBy refsArray={refsArray} columns={columnsNames} />
                  )}

                  <Button
                    onClick={onExportCV}
                    loading={isCsvLoading}
                    variant="outlined"
                    disabled={!ordersData.orders.length}
                  >
                    Export CSV
                  </Button>
                </div>
              </div>

              <LoaderContainer
                loading={
                  isOrdersFetching || isRefundsFetching || isVoidsFetching
                }
              >
                <Table
                  className="mt-4 l:mt-6"
                  columns={columns}
                  data={getData()}
                  headerRefs={refsArray}
                  noRowNumberColumn
                  clickableRow
                  onClickRow={onClickRow}
                />
              </LoaderContainer>

              <Pagination
                pageSize={paginationProps.pageSize}
                count={paginationProps.count || 0}
                className="mx-auto mt-8 l:ml-auto l:mr-0"
                page={currentPage}
                setPage={setCurrentPage}
              />

              <OrderDetailsModal
                isOpen={isOrderDetailsModalOpen}
                orderId={orderDetailsProps.orderId}
                locationId={orderDetailsProps.locationId}
                onClose={() => setIsOrderDetailsModalOpen(false)}
              />
            </div>
          ) : (
            <Text variant="h4">There is no data</Text>
          )
        )
        .exhaustive()}
    </Page>
  );
};
