import { DateRangeOption, Time } from '@types';
import dayjs from 'dayjs';
import { match } from 'ts-pattern';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Toronto');

export enum DateFormats {
  MONTH_YEAR = 'MMMM YYYY',
  SHORT_FORMAT = 'DD/MMM/YYYY',
  LONG_FORMAT = 'DD-MMM-YY HH:mm:ss',
  AMERICAN_FORMAT = 'MM/DD/YYYY',
  AMPM_FORMAT = 'DD/MM/YYYY h:mm A',
}

export const formatDate = (
  date?: Date | string,
  dateFormat: DateFormats = DateFormats.SHORT_FORMAT
) => dayjs(date || new Date()).format(dateFormat);

// export const getHoursFromTime = (time: Time): number =>
//   +time.selectedHour.split(':')[0] + (time.isAMSelected ? 0 : 12);

export const getHoursFromTime = (time: Time): number => {
  const { selectedHour, isAMSelected } = time;
  const hourPart = parseInt(selectedHour.split(':')[0], 10);

  if (hourPart === 12) {
    // 12 AM should be 0 hours, 12 PM should be 12 hours
    return isAMSelected ? 0 : 12;
  }

  // For other hours: if PM, add 12 (1 PM = 13:00, etc.)
  return isAMSelected ? hourPart : hourPart + 12;
};

export const formatTime = (
  date: Date
): { isAMSelected: boolean; selectedHour: string } => {
  const hours = date.getHours();
  const isAMSelected = hours < 12;
  const selectedHour = `${hours % 12 === 0 ? 12 : hours % 12}:00`;

  return { isAMSelected, selectedHour };
};

export const calculateStartDate = (selectedRange: DateRangeOption) => {
  const currentDate = dayjs().tz();

  return match(selectedRange)
    .with('today', () => currentDate.startOf('day'))
    .with('yesterday', () => currentDate.subtract(1, 'day').startOf('day'))
    .with('this-week', () => currentDate.startOf('week'))
    .with('last-week', () => currentDate.subtract(1, 'week').startOf('week'))
    .with('last-7-days', () => currentDate.subtract(7, 'day'))
    .with('this-month', () => currentDate.startOf('month'))
    .with('last-month', () => currentDate.subtract(1, 'month').startOf('month'))
    .with('last-30-days', () => currentDate.subtract(30, 'day'))
    .with('last-3-months', () => currentDate.subtract(3, 'month'))
    .with('last-6-months', () => currentDate.subtract(6, 'month'))
    .with('this-year', () => currentDate.startOf('year'))
    .with('last-year', () => currentDate.subtract(1, 'year').startOf('year'))
    .with('last-12-months', () => currentDate.subtract(12, 'month'))
    .with('custom-date-range', () => currentDate)
    .otherwise(() => currentDate);
};

export const calculateEndDate = (selectedRange: DateRangeOption) => {
  const currentDate = dayjs().tz();

  return match(selectedRange)
    .with('today', () => currentDate)
    .with('yesterday', () => currentDate.subtract(1, 'day').endOf('day'))
    .with('this-week', () => currentDate)
    .with('last-week', () => currentDate.subtract(1, 'week').endOf('week'))
    .with('last-7-days', () => currentDate)
    .with('this-month', () => currentDate)
    .with('last-month', () => currentDate.subtract(1, 'month').endOf('month'))
    .with('last-30-days', () => currentDate)
    .with('last-3-months', () => currentDate)
    .with('last-6-months', () => currentDate)
    .with('this-year', () => currentDate)
    .with('last-year', () => currentDate.subtract(1, 'year').endOf('year'))
    .with('last-12-months', () => currentDate)
    .with('custom-date-range', () => currentDate)
    .otherwise(() => currentDate);
};
